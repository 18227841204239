// 中文文案
export default {
  menuItems: {
    zh: "中文",
    mainmenu: [
      {
        key: "index",
        label: "知识总结",
      },
      {
        key: "project",
        label: "项目汇总",
      },
      {
        key: "exp",
        label: "项目经验",
      },
      {
        key: "link",
        label: "工具链接",
      },
    ],
  },
};

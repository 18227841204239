import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import message from "@/locales/index";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersist from "pinia-plugin-persist";
import "./assets/tailwind.css";

// import store from "./store";
const pinia = createPinia();
pinia.use(piniaPluginPersist);

const i18n = createI18n({
  locale: "zh",
  messages: { zh: message.zh, en: message.en },
  legacy: false,
});

createApp(App).use(pinia).use(router).use(i18n).mount("#app");

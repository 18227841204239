import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
// import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/blog",
    // component: HomeView,
  },
  {
    path: "/blog",
    name: "blog",
    // 路由懒加载
    component: () => import("@/layouts/PageLayout.vue"),
    redirect: "/blog/intro",
    children: [
      {
        path: "intro",
        name: "IntroPage",
        component: () => import("@/views/IntroPage.vue"),
      },
      {
        path: "index",
        name: "IndexView",
        component: () => import("@/views/ContentLayout.vue"),
        redirect: "/blog/index/index.write",
        children: [
          {
            path: ":path",
            name: "IndexViewIndex",
            component: () => import("../views/NoticePage.vue"),
          },
        ],
      },
      {
        path: "project",
        name: "ProjectTotal",
        component: () => import("../views/ContentLayout.vue"),
        redirect: "/blog/project/project.write",
        children: [
          {
            path: ":path",
            name: "ProjectTotalIndex",
            component: () => import("../views/NoticePage.vue"),
          },
        ],
      },
      {
        path: "exp",
        name: "ProjectEXP",
        component: () => import("../views/ContentLayout.vue"),
        redirect: "/blog/exp/exp.write",
        children: [
          {
            path: ":path",
            name: "ProjectExpIndex",
            component: () => import("../views/NoticePage.vue"),
          },
        ],
      },
      {
        path: "link",
        name: "ToolLink",
        component: () => import("../views/ContentLayout.vue"),
        redirect: "/blog/link/link.write",
        children: [
          {
            path: ":path",
            name: "ToolLinkIndex",
            component: () => import("../views/NoticePage.vue"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;

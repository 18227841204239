// 英文文案
export default {
  menuItems: {
    zh: "",
    mainmenu: [
      {
        key: "index",
        label: "INDEX",
      },
      {
        key: "project",
        label: "PROJECT",
      },
      {
        key: "exp",
        label: "EXP",
      },
      {
        key: "link",
        label: "LINK",
      },
    ],
  },
};
